<template>
    <div id="newGuestAccessPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newGuest"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="siteusers_createNewGuest"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_createNewGuest") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="newGuestAccessForm_nameInput">{{ $t("user_firstname") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vFirstname" @input="$v.vFirstname.$touch()" type="text" class="form-control" id="newGuestAccessForm_nameInput" :placeholder="$t('siteusers_guestFirstnamePlaceholder')" />
                                                                <div v-if="!$v.vFirstname.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vFirstname.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vFirstname.$params.minLen.min]) }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("siteusers_guestFirstnameExample") }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="newGuestAccessForm_nameInput">{{ $t("user_lastname") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vLastname" @input="$v.vLastname.$touch()" type="text" class="form-control" id="newGuestAccessForm_nameInput" :placeholder="$t('siteusers_guestLastnamePlaceholder')" />
                                                                <div v-if="!$v.vLastname.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vLastname.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vLastname.$params.minLen.min]) }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("siteusers_guestLastnameExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="newGuestAccessForm_nameInput">{{ $t("siteusers_emailLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-envelope"></i></span>
                                                                </div>
                                                                <input v-model="vEmail" @input="$v.vEmail.$touch()" type="text" class="form-control" id="newGuestAccessForm_emailInput" :placeholder="$t('siteusers_guestEmailPlaceholder')" />
                                                                <div class="invalid-feedback" v-if="!$v.vEmail.required">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div class="invalid-feedback" v-if="!$v.vEmail.email">
                                                                    {{ $t("error_pleaseEnterValidEmail") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("siteusers_guestEmailExample") }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="guestExpirationDatePicker">{{ $t("common_expirationDateTime") }} *</label>
                                                            <div class="input-group date">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-calendar"></i></span>
                                                                </div>
                                                                <input type="text" class="form-control" id="guestExpirationDatePicker" readonly="" :placeholder="$t('common_selectDateTime')" />
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("siteusers_guestExpirationDateExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group validated">
                                                    <label class="marginB20">{{ $t("siteusers_guestAssignRoleText") }}</label>
                                                    <div class="kt-radio-list">
                                                        <label v-for="role in vRolesList" :key="role.id" class="kt-radio kt-radio--bold kt-radio--brand">
                                                            <input type="radio" :id="'roleRadio_' + role.name" name="role" :value="role.id" v-model="vRoleId" checked="checked" /> {{ role.isDefault === true ? $t("ROLE_" + role.name) : role.name }} <br />
                                                            <div class="featuresList">
                                                                (
                                                                <span v-for="(feature, index) in role.features" :key="feature.id">{{ $t("ROLE_FEATURES_" + feature.name) }}<span v-if="index + 1 < role.features.length">, </span></span>
                                                                )
                                                            </div>
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <label class="kt-checkbox kt-margin-r-20">
                                                        <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                        <span></span>
                                                    </label>
                                                    <button id="newGuestAccessCreateButton" @click="onCreateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid">
                                                        {{ $t("common_create") }}
                                                    </button>
                                                    <button id="newGuestAccessCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import moment from 'moment-timezone';

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            vLastname: "",
            vFirstname: "",
            vEmail: "",
            vRoleId: "",
            vRolesList: [],
            vDate: null,
            createAnother: false
        };
    },
    created: function() {
        console.log("Component(NewGuest)::created() - called");
        this.createAnother = this.createAnotherGuest;
        this.getSiteRoles(this.siteId);
    },
    mounted: function() {
        console.log("Component(NewGuest)::mounted() - Init metronic layout");
        KTLayout.init();
        this.initExpirationDateTimePicker();
    },
    beforeDestroy: function() {
        console.log("Component(NewGuest)::beforeDestroy() - called");
        // Reset component data
        this.siteId = null;
        this.vLastname = null;
        this.vFirstname = null;
        this.vEmail = null;
        this.vRoleId = null;
        this.vRolesList = null;
        this.vDate = null;
        // Reset store
        this.resetSiteGuestsState();
        this.resetSiteRolesState();
    },
    destroyed: function() {
        console.log("Component(NewGuest)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vFirstname: {
            required,
            minLen: minLength(2)
        },
        vLastname: {
            required,
            minLen: minLength(2)
        },
        vEmail: {
            required,
            email
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        user: function(user) {
            console.log("Component(NewGuest)::watch(user) - called with : ", user);
        },

        currentSiteUser(siteUser) {
            this.siteUserRole = (siteUser && siteUser.role ? siteUser.role.name : '');
            console.log("Component(NewGuest)::watch(currentSiteUser) - called with : ", siteUser, " - site user role = " + this.siteUserRole);
            // Get authorized guest roles
            if (this.siteRoles) {
                const authorizedRoles = this.siteRoles.filter( (role) => {
                    switch (this.siteUserRole) {
                        case 'ADMIN':
                            // For site user with ADMIN role, authorize all roles for new guest
                            return role;
                        case 'OPERATION_MANAGER':
                            // For site user with OPERATION_MANAGER role, authorize all roles for new guest except ADMIN role (reserved to ADMIN)
                            if (role.name !== 'ADMIN') {
                                return role;
                            }
                            break;
                        default:
                            // For site user with custom role, authorize only the same custom role for new guest
                            if (role.name === this.siteUserRole) {
                                return role;
                            }
                    }
                });
                if (authorizedRoles) {
                    this.vRolesList = _.orderBy(authorizedRoles, [role => role.name.toLowerCase()], ["asc"]);
                    // Put by default the site user role id for radio button
                    for (var i = 0; i < authorizedRoles.length; i++) {
                        if (authorizedRoles[i].name === this.siteUserRole) {
                            this.vRoleId = authorizedRoles[i].id;
                            break;
                        }
                    }
                }
            }
        },

        siteRoles(roles) {
            console.log("Component(NewGuest)::watch(siteRoles) - called with ", roles);
            if (roles) {
                // Get user role on the site before init all roles for a guest
                this.getSiteUserById({
                    siteId: this.$route.params.siteId,
                    userId: this.user.id
                });

            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteRoles", "user", "currentSiteUser", "createAnotherGuest"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteRoles", "createGuestOfSite", "resetSiteGuestsState", "resetSiteRolesState", "getSiteUserById"]),

        initExpirationDateTimePicker() {
            let selector = $("#guestExpirationDatePicker");
            let minDate = commonVueHelper.getToDayPlusXmin(30);
            commonVueHelper.initDateTimePicker(selector, "top-right", minDate, minDate);
            this.vDate = commonVueHelper.datetimeToISOString(minDate);
            selector.on("changeDate", event => {
                if (event.date) {
                    this.vDate = commonVueHelper.datetimeToISOString(event.date.valueOf());
                }
            });
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(NewGuest)::onCancelButton() - called");
            this.$router.push({ name: "listSiteGuests", params: { fromAction: "cancelButton" } });
        },

        onCreateButton() {
            console.log("Component(NewGuest)::onCreateButton() - called");
            const data = {
                siteId: this.siteId,
                lastName: this.vLastname,
                firstName: this.vFirstname,
                email: this.vEmail,
                roleId: this.vRoleId,
                expiredAt: this.vDate,
                timezone: moment.tz.guess(),
                createAnother: this.createAnother
            };
            this.createGuestOfSite(data);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.vFirstname || this.vLastname || this.vEmail) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "NewGuest", "create", !this.isEmptyForm(), to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.featuresList {
    font-size: 12px;
    color: black;
}
</style>
